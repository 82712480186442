import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const BeautifulLife = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">things are great, well ok, but at least we're not&hellip;</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art beautiful_life"></div>
          <div className="column is-half lyrics">
            <p>
              Beautiful life, we’ve found our home. Never again will we worry or want or need
            </p>

            <p>
              &nbsp;&nbsp;Respite is now, but disquiet lingers. Questions arising disturbing the tremulous piece
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;First we were favored. Now it grows colder. We do what we must to exist in a changing word<br/><br/>
            </p>

            <p>
              There are no limits, no reason for doubting. Our world is our destiny brought to us now we are free!
            </p>

            <p>
              &nbsp;&nbsp;Haven’t you noticed a change in the weather? The warm days come rarely the nights make us shiver and moan
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;Deeper is warmer, always an answer. I see a rock with some speckles, nice colors and shapes<br/><br/>
            </p>

            <p>
              Cycles come often. Await the fine summers. The chill makes us stronger. We build and with joy we will live
            </p>

            <p>
              &nbsp;&nbsp;Not this time my friend. It worsens by season our world is in crisis our days of fair glory behind
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;Time slows and crawls, each second a journey. The moments extended, enriched with the essence of time<br/><br/>
            </p>

          </div>

        </div>
      </div>
  </section>
  </Layout>
)

export default BeautifulLife
